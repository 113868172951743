import { inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ResolveFn } from '@angular/router';
import { AuthService } from '../../service/auth.service';

export const userResolver: ResolveFn<User | null> = async (route, state) => {
  const auth = inject(AuthService);
  const user = await auth.waitForUser();
  return user;
};
